import React, {useRef, useState} from 'react';
import './PopupInfo.css';


const PopupInfo = ({ info, closePopup, loadDetail }) => {

    return (
        <div>
            <div className="popup-frame">
                <div className="popup-header"/>
                <div className="popup-image" onClick={loadDetail} >
                    <div className="popup-close" onClick={closePopup}>X</div>
                </div>
                <div className="popup-content">
                    <button className={'popup-content'} onClick={()=>{}}>See more</button>
                </div>
            </div>
        </div>
    );
};

export default PopupInfo;
