import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import './ThreeAnim.css';

import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import {Vector2} from "three";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";

// Import necessary modules from the three.js examples
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';


import background from './assets/graphics/background_web.webp';
import MapScene from './assets/gltf/game_scene.glb';












const checkRefs = (refs) => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            const allRefsReady = refs.every(ref => ref.current);
            if (allRefsReady) {
                clearInterval(interval);
                resolve();
            }
        }, 100); // Check every 100ms
    });
};

//Pre render animation variables
let frameCurrent = 0;
let frameMin = 0;
let frameMax = 0;
let frameArr = [];
let frameImageCache = [];
let frameSpeed = 1000;
let frameType = ".jpg"
let frameUrl = "frames/orbit/background_";
//Preload Images
function AsyncImageLoader(url) {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = url;
        image.onload = () => resolve(image);
        image.onerror = () => reject(new Error('could not load image'));
    });
}

//Cache images / frame data
for (let i = frameMin; i <= frameMax; i++) {
    let iStr = i.toLocaleString('en-US', {minimumIntegerDigits: 5, useGrouping: false});
    let imgUrl = frameUrl + iStr + frameType;
    frameArr.push(imgUrl);
    /*AsyncImageLoader(imgUrl)
        .then(img => {
            console.log(frameArr.length);
            frameImageCache.push(img);
        })
        .catch((err) => {
            console.log(err);
        });*/
}


const ThreeAnim = forwardRef((props, ref) => {
    //const [frameArr, setFrameArr] = useState([]);
    const threeOverlayElemDivRef = useRef(null);
    const frameElemDivRef = useRef(null);
    const frameElemImgRef = useRef(null);

    const localFilterOverlaySceneRef = useRef(null);

    const {onItemSelected} = props;

    // Expose methods to parent component
    useImperativeHandle(ref, () => ({
        onItemSelected: ()=>{},
        callFilterOverlayScene: (args) => {
            if (localFilterOverlaySceneRef.current) {
                localFilterOverlaySceneRef.current(args);
            }
        }
    }));

    //CONTROLS
    const keyState = useRef({
        ArrowUp: false,
        ArrowDown: false,
        ArrowLeft: false,
        ArrowRight: false,
        w: false,
        a: false,
        s: false,
        d: false,
    });

    useEffect(() => {
        const refs = [threeOverlayElemDivRef, frameElemDivRef, frameElemImgRef]

        const checkAllRefs = async () => {
            console.log('Checking refs are ready');
            await checkRefs(refs);
            console.log('All refs are ready');
            const threeOverlayElemDiv = threeOverlayElemDivRef.current;
            const frameElemDiv = frameElemDivRef.current;
            const frameElemImg = frameElemImgRef.current;

            //Mouse trackers
            let mouseIsDown = false;
            let mouseInFrameDownX = 0.0;
            let mouseInFrameDownY = 0.0;
            let mouseDownFrame = 0;
            let pageX = 0;
            let pageY = 0;

            function OnMouseDownInFrames(e) {
                console.log("MOUSE DOWN");
                //Record mouse movements
                mouseInFrameDownX = e.clientX;
                mouseInFrameDownY = e.clientY;
                mouseDownFrame = frameCurrent;
                mouseIsDown = true;
                //Overlay Viewport
                //let overlayViewportRect = threeOverlayElemDiv.getBoundingClientRect();
                //overlayViewportX = ((e.clientX - overlayViewportRect.left) / overlayViewportRect.width) * 2 - 1;
                //overlayViewportY = -((e.clientY - overlayViewportRect.top) / overlayViewportRect.height) * 2 + 1;

                //Clicked on Unit
                console.log(overlaySelectedItem);
                onItemSelected(overlaySelectedItem);
            }

            //Calculate which frame to display
            function OnMouseMoveInFrames(e) {
                if (mouseIsDown) {
                    let frameOffsetX = (e.clientX - mouseInFrameDownX) / window.screen.width;
                    let frameOffsetY = (e.clientY - mouseInFrameDownY) / window.screen.height;
                    //let newFrame = Math.floor(mouseDownFrame + frameOffsetX * frameSpeed) % frameArr.length;
                    //if (newFrame < 0) newFrame = frameArr.length + newFrame;
                    //frameCurrent = newFrame;
                    //frameElemImg.setAttribute('src', frameArr[frameCurrent]);
                    //cameraMixer.setTime(frameCurrent / 30);

                }

                //Overlay Viewport
                let overlayViewportRect = threeOverlayElemDiv.getBoundingClientRect();
                overlayViewportX = ((e.clientX - overlayViewportRect.left) / overlayViewportRect.width) * 2 - 1;
                overlayViewportY = -((e.clientY - overlayViewportRect.top) / overlayViewportRect.height) * 2 + 1;
                //Record mouse positions
                pageX = e.pageX;
                pageY = e.pageY;
            }

            //Pan
            // Function to get mouse position on a specific plane
            const groundPlane = new THREE.Plane(new THREE.Vector3(0,1,0), 0);
            const groundPlaneRaycaster = new THREE.Raycaster();
            let isPanning = false;
            let cameraPosPanStart = new THREE.Vector3();
            let clickPosPanStart = new THREE.Vector3();
            let clickPosPanCurrent = new THREE.Vector3();
            const cameraOffset = new THREE.Vector3();


            function getMousePositionOnGroundPlane(event) {
                const mouse = new THREE.Vector2();
                mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
                groundPlaneRaycaster.setFromCamera(mouse, camera);
                const intersection = new THREE.Vector3();
                groundPlaneRaycaster.ray.intersectPlane(groundPlane, intersection);

                return intersection;
            }

            const handlePanStart = (event) => {
                if (event.button === 1) { // Middle mouse button
                    isPanning = true;
                    cameraPosPanStart.copy(camera.position);
                    clickPosPanStart = getMousePositionOnGroundPlane(event);
                }
            }


            function clamp(value, min, max) {
                return Math.max(min, Math.min(max, value));
            }
            const handlePanMove = (event) => {
                if(!isPanning) return;
                clickPosPanCurrent = getMousePositionOnGroundPlane(event);
                cameraOffset.copy(clickPosPanCurrent);
                cameraOffset.sub(clickPosPanStart);
                cameraOffset.negate();
                console.log('cameraOffset: ', cameraOffset);
                //Pan
                if(isPanning) {
                    let newPos = cameraPosPanStart.clone();

                    newPos.add(cameraOffset.multiplyScalar(10));
                    newPos.x = clamp(newPos.x, mapPlaneBox.min.x, mapPlaneBox.max.x);
                    newPos.z = clamp(newPos.z, mapPlaneBox.min.z, mapPlaneBox.max.z);
                    camera.position.lerp(newPos, 0.05); // Adjust the alpha value (0.1) as needed for smoothness
                }
            };

            const handlePanEnd = (event) => {
                if (event.button === 1) { // Middle mouse button
                    isPanning = false;
                    //camera
                    //cameraOffset.set(0,0,0);
                }
            };
            //Zoom
            const zoomOutLimit = 5000;
            const zoomInLimit = 50;
            const zoomSpeed = 100; // Adjust this value to control zoom speed
            function OnMouseWheelInFrames(e) {
                const delta = Math.sign(e.deltaY); // -1 for zoom in, 1 for zoom out
                const newY = camera.position.y + delta * zoomSpeed;
                if (newY >= zoomInLimit && newY <= zoomOutLimit) {
                    camera.position.y = newY;
                    console.log(newY);
                }
            }

            //Stop playing frames
            function OnMouseUpInFrames(e) {
                mouseIsDown = false;
                mouseDownFrame = frameCurrent;
            }

            //Stop playing frames
            function OnMouseLeaveInFrames(e) {
                mouseIsDown = false;
                mouseDownFrame = frameCurrent;
                isPanning = false;
            }

            let frameDuration = frameArr.length;
            frameElemDiv.addEventListener("pointerdown", OnMouseDownInFrames);
            frameElemDiv.addEventListener("pointermove", OnMouseMoveInFrames);
            frameElemDiv.addEventListener("pointerup", OnMouseUpInFrames);
            frameElemDiv.addEventListener("pointerleave", OnMouseLeaveInFrames);
            frameElemDiv.addEventListener("wheel", OnMouseWheelInFrames);
            //pan
            //frameElemDiv.addEventListener('mousedown', handlePanStart);
            //frameElemDiv.addEventListener('mousemove', handlePanMove);
            //frameElemDiv.addEventListener('mouseup', handlePanEnd);

            //THREE OVERLAY
            /*let fbxCameraUrl = "gltf/map_scene_punta_garrobo.glb";
            let fbxCameraName = "camera";
            let cameraMixer = null;
            let cameraClipAction = null;*/
            let farPlane = 10000;
            let mapPlane;
            let mapPlaneBox;
            //
            //let overlayModelUrl = "/assets/gltf/game_scene.glb";
            let overlayItemsAll = [];
            let overlayItemsOn = [];
            let overlayItemsReady = false;
            let overlayMaterials = {};
            let overlayHighlightMaterials = {};

            //Colors
            let defaultColor = 0xffffff;
            let defaultOpacity = 1;
            let highlightColor = 0xffa6a6;
            let highlightOpacity = 1;
            let baseVisibleOutlineColor = 0x000000;
            let baseHiddenOutlineColor = 0x000000;
            let highlightVisibleOutlineColor = 0xffbbbb;
            let highlightHiddenOutlineColor = 0xffbbbb;

            //Filter Function


            let scene = new THREE.Scene();
            let camera = new THREE.PerspectiveCamera(58.5, window.innerWidth / window.innerHeight, 1, farPlane + 1);
            scene.add(camera);
            let ambientLight = new THREE.AmbientLight(0xFFFFFF, 1.5);
            scene.add(ambientLight);

            let fbxLoader = new FBXLoader();
            let gltfLoader = new GLTFLoader();
            //Load camera animation, attach own camera as child
            /*fbxLoader.load(fbxCameraUrl, function (object) {
                cameraMixer = new THREE.AnimationMixer(object);
                cameraClipAction = cameraMixer.clipAction(object.animations[0]);
                scene.add(object);
                //object.scale.set(1, 1, 1);
                let fbxCamera = object.getObjectByName(fbxCameraName);
                camera.position.x = fbxCamera.position.x;
                camera.position.y = fbxCamera.position.y;
                camera.position.z = fbxCamera.position.z;
                camera.rotation.x = fbxCamera.rotation.x;
                camera.rotation.y = fbxCamera.rotation.y;
                camera.rotation.z = fbxCamera.rotation.z;
                //fbxCamera.attach(camera);
                cameraClipAction.play();
            });*/

            //Load building overlay model
            gltfLoader.load(MapScene, function (gltf) {

                gltf.scene.traverse(function (child) {
                    console.log(child.name);
                    if (child instanceof THREE.Mesh && child.name !== 'game_background') {
                        const originalTexture = child.material.map;
                        let childMaterial = new THREE.MeshBasicMaterial(
                            {color: defaultColor, transparent: true, opacity: defaultOpacity, map: originalTexture});
                        let childHighlightMaterial = new THREE.MeshBasicMaterial(
                            {color: highlightColor, transparent: true, opacity: highlightOpacity, map: originalTexture});
                        overlayItemsAll.push(child);
                        overlayItemsOn.push(child);
                        overlayMaterials[child.id] = childMaterial;
                        overlayHighlightMaterials[child.id] = childHighlightMaterial;

                        child.material = childMaterial;
                    } else if (child.name === 'game_background') {
                        const originalTexture = child.material.map;
                        // Ensure texture encoding matches renderer encoding
                        originalTexture.colorSpace = THREE.LinearSRGBColorSpace;
                        originalTexture.generateMipmaps = false;
                        let childMaterial = new THREE.MeshBasicMaterial({map: originalTexture});
                        child.material = childMaterial;
                        mapPlane = child;

                    }

                    overlayItemsReady = true;
                });
                scene.add(gltf.scene);
                if (gltf.cameras && gltf.cameras.length > 0) {
                    camera.copy(gltf.cameras[0]);
                    camera.near = 1;
                    camera.far = farPlane;
                    //start zoom val
                    camera.position.y = zoomOutLimit;
                }
                mapPlaneBox = new THREE.Box3().setFromObject(mapPlane);
            });
            //
            // Create a box geometry (cube)
            const geometry = new THREE.BoxGeometry(10, 10, 10); // Width, height, depth of 1 unit

            // Create a material (basic with a color)
            const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 }); // Green color

            // Create a mesh (geometry + material) and assign it to the player variable
            const player = new THREE.Mesh(geometry, material);
            const playerHeight = 22;

            // Add the cube (player) to the scene
            scene.add(player);
            player.position.y = playerHeight;

            //Raycaster params
            let overlayRaycaster = new THREE.Raycaster();
            let overlayViewportX = 0;
            let overlayViewportY = 0;
            let overlaySelectedItem = null;

            //Renderer setup
            let renderer = new THREE.WebGLRenderer({alpha: true, outputEncoding: THREE.SRGBColorSpace});
            threeOverlayElemDiv.appendChild(renderer.domElement);
            //Render Passes
            let effectComposer = new EffectComposer(renderer);
            const renderPass = new RenderPass(scene, camera);

            let outlinePassBase = new OutlinePass(
                new Vector2(threeOverlayElemDiv.offsetWidth, threeOverlayElemDiv.offsetHeight), scene, camera, overlayItemsOn);
            renderer.setSize(threeOverlayElemDiv.offsetWidth, threeOverlayElemDiv.offsetHeight);

            let outlinePassHighlight = new OutlinePass(
                new Vector2(threeOverlayElemDiv.offsetWidth, threeOverlayElemDiv.offsetHeight), scene, camera);
            renderer.setSize(threeOverlayElemDiv.offsetWidth, threeOverlayElemDiv.offsetHeight);

            outlinePassBase.edgeStrength = 3.0;
            outlinePassBase.edgeGlow = 0.0;
            outlinePassBase.edgeThickness = 0.3;
            outlinePassBase.pulsePeriod = 0;
            outlinePassBase.rotate = false;
            outlinePassBase.usePatternTexture = false;
            outlinePassBase.visibleEdgeColor.set(baseVisibleOutlineColor);
            outlinePassBase.hiddenEdgeColor.set(baseHiddenOutlineColor);


            outlinePassHighlight.edgeStrength = 3.0;
            outlinePassHighlight.edgeGlow = 0.0;
            outlinePassHighlight.edgeThickness = 1.0;
            outlinePassHighlight.pulsePeriod = 0;
            outlinePassHighlight.rotate = false;
            outlinePassHighlight.usePatternTexture = false;
            outlinePassHighlight.visibleEdgeColor.set(highlightVisibleOutlineColor);
            outlinePassHighlight.hiddenEdgeColor.set(highlightHiddenOutlineColor);

            effectComposer.addPass(renderPass);
            effectComposer.addPass(outlinePassBase);
            effectComposer.addPass(outlinePassHighlight);

            //RE-PROJECTION effects style decisions in html! Overlay style must work together with projection.
            function RefreshCameraProjection() {
                if (camera != null && frameArr.length > 0) {
                    //use frame / background image as ref on how to re-project
                    let frameElemImgRect = frameElemImg.getBoundingClientRect();
                    camera.aspect = frameElemImgRect.width / frameElemImgRect.height;
                    camera.updateProjectionMatrix();
                    renderer.setSize(frameElemImgRect.width, frameElemImgRect.height);
                    outlinePassBase.setSize(frameElemImgRect.width, frameElemImgRect.height);
                    outlinePassHighlight.setSize(frameElemImgRect.width, frameElemImgRect.height);
                    effectComposer.setSize(frameElemImgRect.width, frameElemImgRect.height);
                }
            }

            function RaycastCheck() {
                //Update mouse position based raycasting parameters
                overlayRaycaster.setFromCamera(new THREE.Vector2(overlayViewportX, overlayViewportY), camera);
                let intersectedItems = overlayRaycaster.intersectObjects(overlayItemsOn);

                //check if there are no intersects, if here are not, return current hovered item to original state and release it
                if (intersectedItems.length > 0) {
                    //only do something if the user isnt rotating the model / playing the frames
                    let validIndex = -1;
                    for (let i = 0; i < intersectedItems.length; i++) {
                        if (intersectedItems[i].object.visible === true) {
                            validIndex = i;
                            break;
                        }
                    }
                    if (!mouseIsDown && validIndex !== -1) {
                        //Change back old item to normal material
                        if (overlaySelectedItem != null && intersectedItems[validIndex].object.id !== overlaySelectedItem.id) {
                            overlaySelectedItem.material = overlayMaterials[overlaySelectedItem.id];
                        }
                        //highlight new hovered item
                        overlaySelectedItem = intersectedItems[validIndex].object;
                        overlaySelectedItem.material = overlayHighlightMaterials[overlaySelectedItem.id];
                        //highlight outline for hovered item
                        outlinePassHighlight.selectedObjects = [overlaySelectedItem];
                        //unit detail display

                        ///
                    }
                } else {
                    //Change back old item to normal material
                    if (overlaySelectedItem != null) {
                        overlaySelectedItem.material = overlayMaterials[overlaySelectedItem.id];
                        //highlight outline for hovered item
                        outlinePassHighlight.selectedObjects = [];
                        //hide unit details
                        ///
                    }
                    overlaySelectedItem = null;
                }
            }

            //LINE DRAW
            const playerLineHeight = 21
            const playerLinePointMinDist = 1.0;
            let playerLinePoints = []; // Ensure at least one initial point
            // Create a BufferGeometry and set the initial points
            let playerLineGeometry = new THREE.BufferGeometry().setFromPoints(playerLinePoints);
            // Create the material
            let playerLineMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff, lineWidth: 10000 });

            // Create the line using the geometry and material
            let playerLines = new THREE.Line(playerLineGeometry, playerLineMaterial);
            // Add the line to the scene
            scene.add(playerLines);
            const updatePlayerLinePoints = (p) => {
                // Create a new point based on the player's position and ensure height is set
                //const newPoint = new THREE.Vector3(p.x, playerLineHeight, p.z);
                const newPoint = new THREE.Vector3(Math.round(p.x), playerLineHeight, Math.round(p.z));
                if(playerLinePoints.length > 0) {
                    if (newPoint.distanceTo(playerLinePoints[playerLinePoints.length - 1]) >= playerLinePointMinDist) {
                        //const midpoint = new THREE.Vector3();
                        // Add pointA and pointB, then multiply by 0.5 to get the midpoint
                        //midpoint.addVectors(newPoint, playerLinePoints[playerLinePoints.length - 1]).multiplyScalar(0.5);
                        //playerLinePoints.push(midpoint);
                        playerLinePoints.push(newPoint);
                        playerLineGeometry.setFromPoints(playerLinePoints);
                        // Ensure the line geometry updates to reflect the correct number of points
                        playerLineGeometry.setDrawRange(0, playerLinePoints.length);
                        console.log('Added point: ', p, ' Total Points: ', playerLinePoints.length);
                    }
                } else {
                    playerLinePoints.push(newPoint);
                    playerLineGeometry.setFromPoints(playerLinePoints);
                    // Ensure the line geometry updates to reflect the correct number of points
                    playerLineGeometry.setDrawRange(0, playerLinePoints.length);
                    console.log('Added point: ', p, ' Total Points: ', playerLinePoints.length);
                }
            }
            //LINE FUNCTIONS
            // Function to check if the line forms a closed shape
            let canCloseShape = false
            const isClosedShape = (points) => {
                if (points.length < 3) {
                    // Not enough points to form a closed shape
                    return false;
                }

                // Get the first and last points
                const firstPoint = points[0];
                const lastPoint = points[points.length - 1];

                // Define a tolerance for floating-point comparison (optional)
                const tolerance = 8.0;

                // Check if the first and last points are close enough to be considered the same
                const isClosed = Math.abs(firstPoint.x - lastPoint.x) < tolerance &&
                    Math.abs(firstPoint.y - lastPoint.y) < tolerance &&
                    Math.abs(firstPoint.z - lastPoint.z) < tolerance;

                return isClosed;
            };

            const flatPolygonHeight = 20;
            const createFlatPolygon = (points, scene, color = 0xffffff) => {
                // Create a shape from the 3D points using x and z for the XZ plane
                const shape = new THREE.Shape();

                // Use x and z coordinates from the points, interpreting z as y (since Shape works in XY plane)
                const firstPoint = new THREE.Vector2(points[0].x, points[0].z);  // Use x, z for the shape
                shape.moveTo(firstPoint.x, firstPoint.y);

                for (let i = 1; i < points.length; i++) {
                    const point = new THREE.Vector2(points[i].x, points[i].z);
                    shape.lineTo(point.x, point.y);  // Use x, z coordinates to define the shape
                }

                // Connect the last point back to the first to close the shape
                shape.lineTo(firstPoint.x, firstPoint.y);

                // Create a flat geometry from the shape (no depth)
                const shapeGeometry = new THREE.ShapeGeometry(shape);

                // Create a material for the flat polygon
                const shapeMaterial = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide });

                // Create the mesh from the geometry and material
                const flatMesh = new THREE.Mesh(shapeGeometry, shapeMaterial);

                // Position the flat polygon on the XZ plane (optional)
                flatMesh.rotation.x = Math.PI / 2;  // Rotate to lie flat on the XZ plane (facing Y axis)
                flatMesh.position.y = flatPolygonHeight;
                // Add the flat polygon to the scene
                scene.add(flatMesh);
            };



            //CONTROLS
            const moveSpeed = 1;
            const movePlayer = () => {
                let finalMove = new THREE.Vector3();
                if (keyState.current.arrowup || keyState.current.w) finalMove.z -= 1;
                if (keyState.current.arrowdown || keyState.current.s) finalMove.z += 1;
                if (keyState.current.arrowleft || keyState.current.a) finalMove.x -= 1;
                if (keyState.current.arrowright || keyState.current.d) finalMove.x += 1;
                finalMove.multiplyScalar(moveSpeed);
                finalMove.x = Math.round(finalMove.x);
                finalMove.y = Math.round(finalMove.y);
                finalMove.z = Math.round(finalMove.z);
                //console.log('Move by: ', finalMove, player.position);


                player.position.x = Math.round(player.position.x);
                player.position.y = Math.round(player.position.y);
                player.position.z = Math.round(player.position.z);

                player.position.add(finalMove);
            };

            //
            let fps = 60;  // Target frames per second
            let interval = 1000 / fps;  // Interval between frames in milliseconds (1000ms / 60fps = ~16.67ms)

            let lastTime = performance.now();
            let deltaTime = 0;
            let animate = function () {
                let currentTime = performance.now();
                deltaTime += currentTime - lastTime;
                lastTime = currentTime;

                // If enough time has passed, update the game logic
                if (deltaTime >= interval) {
                    //CONTROLS
                    movePlayer();
                    //LINE
                    updatePlayerLinePoints(player.position);
                    //LINE STATE
                    if(playerLinePoints.length > 1) {
                        let lineLength = playerLinePoints[0].distanceTo(playerLinePoints[playerLinePoints.length - 1]);
                        if(lineLength > 8) {
                            canCloseShape = true;
                        }
                        if (canCloseShape && isClosedShape(playerLinePoints)) {
                            console.log("The line forms a closed shape!");
                            createFlatPolygon(playerLinePoints, scene, 0xffffff);
                            playerLinePoints = [];
                            canCloseShape = false;
                        } else {
                            console.log("The line is not yet closed.");
                        }
                    }
                    //Match camera to html element aspects and sizes
                    RefreshCameraProjection();
                    //Carry out 3D raycast checks and changes
                    //RaycastCheck();
                    //Render
                    effectComposer.render();
                }
                requestAnimationFrame(animate);

            };
            animate();
            console.log('animate ran');



            //CONTROLS
            const handleKeyDown = (event) => {
                const key = event.key.toLowerCase();
                keyState.current = { ...keyState.current, [key]: true };
            };

            const handleKeyUp = (event) => {
                const key = event.key.toLowerCase();
                keyState.current = { ...keyState.current, [key]: false };
            };

            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);


            // Cleanup on unmount
            return () => {
                threeOverlayElemDiv.removeChild(renderer.domElement);
                frameElemDiv.removeEventListener("pointerdown", OnMouseDownInFrames);
                frameElemDiv.removeEventListener("pointermove", OnMouseMoveInFrames);
                frameElemDiv.removeEventListener("pointerup", OnMouseUpInFrames);
                frameElemDiv.removeEventListener("pointerleave", OnMouseLeaveInFrames);
                frameElemDiv.removeEventListener("wheel", OnMouseWheelInFrames);
                //pan
                //frameElemDiv.removeEventListener('mousedown', handlePanStart);
                //frameElemDiv.removeEventListener('mousemove', handlePanMove);
                //frameElemDiv.removeEventListener('mouseup', handlePanEnd);
                //CONTROLS
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('keyup', handleKeyUp);
            };
        }


        // Start the check and run threejs code
        checkAllRefs();





    }, []);





    return (
        <>
            <div onClick={()=>{console.log('A');}} ref={frameElemDivRef} className="FrameElemDiv" draggable="false">
                <img ref={frameElemImgRef} className="BackgroundFrameImg"  draggable="false"/>
            </div>
            <div onClick={()=>{console.log('B');}} ref={threeOverlayElemDivRef} className="ThreeOverlay" draggable="false"/>
        </>

    );
});

export default ThreeAnim;
