import './App.css';
import React, { useState, useRef } from 'react';
import ThreeAnim from './ThreeAnim';
import logo from './assets/graphics/logo.svg';
import PopupInfo from "./PopupInfo";
//INPUT START
//TEMP

//INPUT END

function App() {
    const [typeVila, setTypeVila] = useState(true);
    const [typeLot, setTypeLot] = useState(false);
    const [waterfront, setWaterFront] = useState(false);
    const [openOceanView, setOpenOceanView] = useState(true);
    const [bayView, setBayView] = useState(false);
    const [available, setAvailable] = useState(true);
    const [notAvailable, setNotAvailable] = useState(false);
    const [reserved, setReserved] = useState(false);
    const [sold, setSold] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [isMenuDetailOpen, setIsMenuDetailOpen] = useState(false);
    const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);

    const [sliderValueSize, setSliderValueSize] = useState(250);
    const [sliderValuePrice, setSliderValuePrice] = useState(1100000);


    const [popupOn, setPopupOn] = useState(false);
    const [popupInfo, setPopupInfo] = useState({
        type: 'Villa',
        pricePerUnit: 0,
        totalArea: 0,
        waterFront: true,
        oceanView: true,
        bayView: true,
        description: 'EMPTY',

        name: 'None',
        dir: process.env.REACT_APP_MEDIA_URL,
        media: []
    });

    //GALLERY
    const galleryRef = useRef(null);





    //STATUS

    const toggleAvailable = () => {
        setAvailable(!available);
    };
    const toggleSold = () => {
        setSold(!sold);
    };
    const toggleNotAvailable = () => {
        setNotAvailable(!notAvailable);
    };
    const toggleReserved = () => {
        setReserved(!reserved);
    };
    //

    //FILTER FUNCTION
    const [isFilterOn, setIsFilterOn] = useState(false);

    const toggleIsFilterOn = () => {
        setIsFilterOn(!isFilterOn);

    }

    const threeAnimRef = useRef(null);



    const updateFilter = () => {
        let bedrooms = '';

        let filter = {"bedrooms": bedrooms.slice(0, -1)}

        if (threeAnimRef.current) {
            threeAnimRef.current.callFilterOverlayScene(filter);
        }
    }
    //

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleMenuDetail = () => {
        setIsMenuDetailOpen(!isMenuDetailOpen);
    };

    const toggleSublist = (index) => {
        if(openSubmenuIndex === index){
            setOpenSubmenuIndex(null);
            setActiveButtonIndex(null);
            setIsMenuDetailOpen(false);
        } else {
            setOpenSubmenuIndex(index);
            setActiveButtonIndex(index);

            if(index === 0){
                setIsMenuDetailOpen(false);
            } else if(index === 1) {
                setIsMenuDetailOpen(true);
            } else if(index === 2) {
                setIsMenuDetailOpen(true);
            } else if(index === 3) {
                setIsMenuDetailOpen(true);
            } else if(index === 4) {
                setIsMenuDetailOpen(true);
            } else if(index === 5) {
                setIsMenuDetailOpen(false);
            } else {

            }


        }


    };


    function toggleTypeLot() {
        setTypeLot(true);
        setTypeVila(false);
    }

    function toggleTypeVila() {
        setTypeLot(false);
        setTypeVila(true);
    }

    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(1100000);

    const onMinPriceChange = (e) => {
        const value = Math.min(e.target.value, maxPrice - 5000); // Ensure min is always less than max
        setMinPrice(parseInt(value));
    };

    const onMaxPriceChange = (e) => {
        const value = Math.max(e.target.value, minPrice + 5000); // Ensure max is always greater than min
        setMaxPrice(parseInt(value));
    };


    function onRangeChangePrice(e) {
        setSliderValuePrice(e.target.value);
    }

    function toggleWaterfront() {
        setWaterFront(!waterfront);
    }

    function toggleOpenOceanView() {
        setOpenOceanView(!openOceanView);
    }

    function resetAllFilters() {
        setTypeVila(true);
        setTypeLot(false);

        setOpenOceanView(true);
        setBayView(false);
        setWaterFront(false);

        setAvailable(true);
        setNotAvailable(false);
        setSold(false);
        setReserved(false);

        setSliderValuePrice(1100000);
        setSliderValueSize(250);
    }

    function onRangeChangeSize(e) {
        setSliderValueSize(e.target.value)
    }

    function toggleBayView() {
        setBayView(!bayView);
    }

    const sliderStylePrice = {
        background: `linear-gradient(to right, #CC694C ${sliderValuePrice / 31500}%, #F7E0C5 ${sliderValuePrice / 31500}%)`
    };
    const sliderStyleSize = {
        background: `linear-gradient(to right, #CC694C ${sliderValueSize / 15}%, #F7E0C5 ${sliderValueSize / 15}%)`
    };

    async function onItemSelected (item) {
        if(item !== null) {
            console.log(item.name, 'selected!')
            //Get stuff

        } else {
            console.log('error selected  item is null!');
        }
    }

    return (
        <div draggable="false">
            <ThreeAnim ref={threeAnimRef} onItemSelected={onItemSelected}/>
            <div className="App" onClick={()=>{console.log('aaaaX');}} draggable="false">
                {popupOn ? <PopupInfo info={popupInfo} closePopup={()=>{setPopupOn(false)}} /> : <></>}
                <button className="menu-button" onClick={()=>{setPopupOn(true)}}>
                    {isMenuOpen ? 'X' : 'O'}
                </button>

                <div onClick={()=>{console.log('Y');}} className={`slider-menu ${isMenuOpen ? 'open' : ''}`}>
                    <div className="container-logo">
                        <img className="logo" src={logo} alt="Logo"/>
                    </div>
                    <div className="container-type">
                        <span>Select Type</span>
                        <div className="select-type-buttons">
                            <button onClick={toggleTypeVila} className={`select-type-button ${typeVila ? 'active' : ''}`}>X</button>
                            <button onClick={toggleTypeLot} className={`select-type-button ${typeLot ? 'active' : ''}`}>Y</button>
                        </div>
                    </div>
                    <div className="container-price">
                        <span className="label">XXX</span>
                        <div className="container-price-text">
                            <span className="container-price-min">{minPrice.toLocaleString()}</span>
                            <span className="container-price-mid">-</span>
                            <span className="container-price-max">{maxPrice.toLocaleString()}</span>
                        </div>
                        <div className="range-slider" style={{ '--minValue': minPrice, '--maxValue': maxPrice }}>
                            <input
                                type="range"
                                min="0"
                                max="3150000"
                                step="5000"
                                value={minPrice}
                                onChange={onMinPriceChange}
                                className="slider"
                                style={{ zIndex: minPrice > 0 ? 1 : 2 }}
                            />

                            <input
                                type="range"
                                min="0"
                                max="3150000"
                                step="5000"
                                value={maxPrice}
                                onChange={onMaxPriceChange}
                                className="slider"
                                style={{ zIndex: maxPrice < 3150000 ? 2 : 1 }}
                            />
                        </div>
                        <div className="range-values">
                            <span className={"range-values-left"}>0</span>
                            <span></span>
                            <span className={"range-values-right"}>3150000</span>
                        </div>
                    </div>
                    <div className="container-features">
                        <div className="toggle-parent">
                            <div className="toggle-container">
                                <div className={waterfront ? 'switch active' : 'switch'} onClick={toggleWaterfront} />
                                <div className="container-features-label">0</div>
                            </div>
                            <div className="toggle-container">
                                <div className={openOceanView ? 'switch active' : 'switch'} onClick={toggleOpenOceanView} />
                                <div className="container-features-label">1</div>
                            </div>
                            <div className="toggle-container">
                                <div className={bayView ? 'switch active' : 'switch'} onClick={toggleBayView} />
                                <div className="container-features-label">2</div>
                            </div>
                        </div>

                    </div>
                    <div className="container-status">
                        <div className="status-title">Status</div>

                        <div className="button-container">
                            <button onClick={toggleAvailable} className={available ? 'inline-button active' : 'inline-button'}>A</button>
                        </div>

                        <div className="button-container">
                            <button onClick={toggleNotAvailable} className={notAvailable ? 'inline-button active' : 'inline-button'}>B</button>
                        </div>

                        <div className="button-container">
                            <button onClick={toggleSold} className={sold ? 'inline-button active' : 'inline-button'}>C</button>
                        </div>

                        <div className="button-container">
                            <button onClick={toggleReserved} className={reserved ? 'inline-button active' : 'inline-button'}>D</button>
                        </div>

                        <div className="button-container">
                            <button onClick={resetAllFilters} className="inline-button-text">
                                <span>Reset all filters</span>
                            </button>
                        </div>
                    </div>


                    {/*<ul>
                        <li className={activeButtonIndex === 0 ? 'active' : ''}>

                            <ul className="sublist open">
                                <li>
                                    <div className="label">Select Type:</div>
                                    <div className="button-container">
                                        <button onClick={toggleTypeVila} className={typeVila ? 'inline-button active' : 'inline-button'}>Vila</button>
                                        <button onClick={toggleTypeLot} className={typeLot ? 'inline-button active' : 'inline-button'}>Lot</button>
                                    </div>
                                </li>
                                <li>
                                    <div className="label">Total Price: {sliderValuePrice}</div>
                                    <div className="range-slider">
                                        <input
                                            type="range"
                                            min="0"
                                            step="5000"
                                            max="3150000"
                                            value={sliderValuePrice}
                                            onChange={onRangeChangePrice}
                                            className="slider"
                                            style={sliderStylePrice}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="toggle-container">
                                        <div className={waterfront ? 'switch active' : 'switch'} onClick={toggleWaterfront}/>
                                        <div className="label">Waterfront</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="toggle-container">
                                        <div className={openOceanView ? 'switch active' : 'switch'} onClick={toggleOpenOceanView}/>
                                        <div className="label">Open Ocean View</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="toggle-container">
                                        <div className={bayView ? 'switch active' : 'switch'} onClick={toggleBayView}/>
                                        <div className="label">Bay View</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="label">Area (m²): {sliderValueSize}</div>
                                    <div className="range-slider">
                                        <input
                                            type="range"
                                            min="0"
                                            max="1500"
                                            step="50"
                                            value={sliderValueSize}
                                            onChange={onRangeChangeSize}
                                            className="slider"
                                            style={sliderStyleSize}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="button-container">
                                        <button onClick={toggleAvailable} className={available ? 'inline-button active' : 'inline-button'}>Available</button>
                                    </div>
                                </li>
                                <li>
                                    <div className="button-container">
                                        <button onClick={toggleNotAvailable} className={notAvailable ? 'inline-button active' : 'inline-button'}>Not Available</button>
                                    </div>
                                </li>
                                <li>
                                    <div className="button-container">
                                        <button onClick={toggleSold} className={sold ? 'inline-button active' : 'inline-button'}>Sold</button>
                                    </div>
                                </li>
                                <li>
                                    <div className="button-container">
                                        <button onClick={toggleReserved} className={reserved ? 'inline-button active' : 'inline-button'}>Reserved</button>
                                    </div>
                                </li>
                                <li>
                                    <div className="button-container">
                                        <button onClick={resetAllFilters} className="inline-button-text">
                                            <span>Reset All Filters</span></button>
                                    </div>
                                </li>
                            </ul>

                        </li>
                    </ul>*/}
                </div>
            </div>
        </div>
    );
}

export default App;
